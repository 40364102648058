exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-deskly-index-tsx": () => import("./../../../src/pages/deskly/index.tsx" /* webpackChunkName: "component---src-pages-deskly-index-tsx" */),
  "component---src-pages-guild-index-tsx": () => import("./../../../src/pages/guild/index.tsx" /* webpackChunkName: "component---src-pages-guild-index-tsx" */),
  "component---src-pages-healthyme-index-tsx": () => import("./../../../src/pages/healthyme/index.tsx" /* webpackChunkName: "component---src-pages-healthyme-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-omantel-index-tsx": () => import("./../../../src/pages/omantel/index.tsx" /* webpackChunkName: "component---src-pages-omantel-index-tsx" */),
  "component---src-pages-tilo-index-tsx": () => import("./../../../src/pages/tilo/index.tsx" /* webpackChunkName: "component---src-pages-tilo-index-tsx" */)
}

